import React, { FC, useEffect, useState } from 'react';
import { Button, Form, Modal, Popover, Tabs } from 'antd';
import moment from 'moment';
import { Doctor, Patient, PriceData, Sex } from 'src/types/users';
import { Roles } from 'src/types/common';
import { UserStatus, UserStatusList } from 'src/consts/users';
import { getOrg, getOrgChatTypes, isAdmin } from 'src/utils/sessionStorage';
import { apiCreatePrice, apiCreateUser, apiGetPriceList, apiUpdatePrice, apiUpdateUser, apiDeletePrice } from 'src/api/endpoints';
import { EditDoctorForm } from 'src/components/Forms/EditDoctorForm';
import { EditPatientForm } from 'src/components/Forms/EditPatientForm';
import { TextEditor } from 'src/components/Editor/TextEditor';
import { EditPriceForm } from 'src/components/Forms/EditPriceForm';
import { useOrgs } from 'src/api/hooks';

type EditUserProps = {
    visible: boolean;
    onCancel: () => void;
    user?: Doctor & Patient;
    role: Roles;
};

const getDefaultPrice = (types: string): Record<string, { id: number, price?: number }> | undefined => {
    const chatTypes = types.split(',');

    if (chatTypes.length > 0) {
        return chatTypes.reduce((res, type) => ({ ...res, [type]: { id: 0, price: undefined } }), {});
    }
    return undefined;
}

export const EditUser: FC<EditUserProps> = ({
    user,
    visible,
    onCancel,
    role
}) => {
    const [form] = Form.useForm<Doctor & Patient>();
    const { fetchOrgs, isLoading, orgs } = useOrgs();
    const [loading, setLoading] = useState<boolean>(false);
    const [price, setPrice] = useState<Record<string, { id: number, price?: number }> | undefined>();
    const [needUpdatePrice, setNeedUpdatePrice] = useState<boolean>(false);
    const org_id = Form.useWatch('organisation_id', form);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                status: UserStatus.INACTIVE,
                sex: role === Roles.Patient ? Sex.Male : undefined
            });

            if (isAdmin()) {
                fetchOrgs();
            }

            if (role === Roles.Doctor) {
                if (user) {
                    if (user?.organisation_id && user?.doctor_id && user?.organisation?.chatTypes) {
                        apiGetPriceList({ organisation_id: user.organisation_id, doctor_id: user.doctor_id, type_id: user.organisation.chatTypes as string })
                            .then(({ list }) => {
                                const chatTypes = (user?.organisation?.chatTypes as string).split(',');
                                if (list?.length > 0) {
                                    if (chatTypes.length > 0) {
                                        const newPrice = chatTypes.reduce<Record<string, { id: number, price?: number }>>((res, type) => {
                                            const dataPrice = list.filter(({ type_id }) => type_id === +type);
                                            if (dataPrice && dataPrice[dataPrice.length - 1]) {
                                                return {
                                                    ...res,
                                                    [type]: {
                                                        id: dataPrice[dataPrice.length - 1].id,
                                                        price: dataPrice[dataPrice.length - 1].price
                                                    }
                                                }
                                            } else {
                                                return {
                                                    ...res,
                                                    [type]: { id: 0, price: undefined }
                                                }
                                            }
                                        }, {});
                                        setPrice(newPrice)
                                    } else {
                                        setPrice(undefined);
                                    }
                                } else {
                                    setPrice(getDefaultPrice(user?.organisation?.chatTypes as string));
                                }
                            })
                            .catch(() => {
                                setPrice(getDefaultPrice(user?.organisation?.chatTypes as string));
                            });
                    }
                } else {
                    if (!isAdmin()) {
                        const types = getOrgChatTypes();
                        if (types) {
                            setPrice(getDefaultPrice(types));
                        }
                    }
                }
            }
        }
    }, [visible]);

    useEffect(() => {
        if (user) {
            const userFields = {
                ...user,
                status: UserStatusList[+user.status - 1]
            };
            if (role === Roles.Patient) {
                userFields.birthday = moment(user.birthday);
            }
            form.setFieldsValue(userFields);
        }
    }, [user]);

    useEffect(() => {
        if (isAdmin() && orgs.length > 0) {
            if (org_id) {
                const [newOrg] = orgs.filter(({ id }) => id === org_id);
                setPrice(getDefaultPrice(newOrg?.chatTypes as string))
            } else {
                setPrice(undefined);
            }
        }
    }, [org_id]);

    const onSavePrice = (doctor_id: number, organisation_id?: number) => {
        if (price) {
            const priceArr = Object.entries(price);
            const newPrice = priceArr.filter(([_, { id, price }]) => id === 0 && price !== undefined);
            const oldPrice = priceArr.filter(([_, { id, price }]) => id !== 0 && price !== undefined);
            const deletedPrice = priceArr.filter(([_, { id, price }]) => id !== 0 && price === undefined);
            let promiseArr = [];
            if (newPrice.length > 0) {
                const result = newPrice.reduce<PriceData>((acc, [type_id, { price }]) => {
                    acc.push({
                        organisation_id: organisation_id || 0,
                        doctor_id,
                        type_id: +type_id,
                        price
                    });
                    return acc;
                }, []);

                promiseArr.push(apiCreatePrice(result));
            }

            if (oldPrice.length > 0) {
                oldPrice.forEach(([_, { id, price }]) => {
                    promiseArr.push(apiUpdatePrice(id, price || 0));
                });
            }

            if (deletedPrice.length > 0) {
                deletedPrice.forEach(([_, { id }]) => {
                    promiseArr.push(apiDeletePrice(id));
                });
            }

            Promise.all(promiseArr)
                .then(() => {
                    onCancel();
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    };

    const onSuccess = () => {
        const apiFunc = user ? apiUpdateUser : apiCreateUser;
        const userData = form.getFieldsValue();
        if (user) {
            userData.phone = user.phone;
        }
        if (role === Roles.Patient) {
            userData.birthday = moment(userData.birthday).format('YYYY-MM-DD');
        } else {
            if (!isAdmin()) {
                const orgId = getOrg();
                if (orgId) {
                    userData.organisation_id = orgId;
                }
            }
        }

        setLoading(true);
        apiFunc({
            ...userData,
            role
        })
            .then(({ data }) => {
                if (needUpdatePrice) {
                    if (role === Roles.Doctor) {
                        if (user?.doctor_id) {
                            onSavePrice(user.doctor_id, userData?.organisation_id);
                        } else if (data?.doctor_id) {
                            onSavePrice(data.doctor_id, userData?.organisation_id);
                        }
                    }
                } else {
                    onCancel();
                }
            })
            .finally(() => {
                if (!needUpdatePrice) {
                    setLoading(false);
                }
            });
    }

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                onSuccess();
            });
    };

    const onUpdatePrice = (price: Record<string, { id: number, price?: number }> | undefined) => {
        if (!needUpdatePrice) {
            setNeedUpdatePrice(true);
        }
        setPrice(price);
    };

    const getTitle = (): string => {
        if (user) {
            return `Редактирование ${role === Roles.Doctor ? 'врача' : 'пациента'}`;
        } else {
            return `Добавление нового ${role === Roles.Doctor ? 'врача' : 'пациента'}`;
        }
    };

    const tabItems = [
        {
            label: 'Общие сведения',
            key: 'general',
            children: (
                <EditDoctorForm
                    form={form}
                    user={user}
                    isLoading={isLoading}
                    orgs={orgs}
                />
            )
        },
        {
            label: 'Описание',
            key: 'description',
            children: <TextEditor />
        },
        {
            label: 'Прайс-лист',
            key: 'price',
            children: <EditPriceForm priceList={price} updatePrice={onUpdatePrice} />
        },
    ];

    const validatePrice = (): boolean => {
        if (price) {
            const emptyPrice = Object.values(price).filter(({ price }) => price === 0);
            return emptyPrice.length <= 0;
        }

        return false;
    }

    return (
        <Modal
            title={getTitle()}
            open={visible}
            onCancel={onCancel}
            afterClose={() => {
                form.resetFields();
                setPrice(undefined);
                setNeedUpdatePrice(false);
            }}
            footer={false}
        >
            <div id="custom-user-form">
                {role === Roles.Doctor && (
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Tabs
                            defaultActiveKey="general"
                            items={tabItems}
                        />
                    </Form>
                )}
                {role === Roles.Patient && (
                    <EditPatientForm
                        form={form}
                        user={user}
                    />
                )}
            </div>
            <div className="ant-modal-footer" style={{ margin: '24px -24px -24px'}}>
                <Button onClick={onCancel} disabled={loading}>
                    Отмена
                </Button>
                {/* validatePrice() ? */(
                    <Button
                        type="primary"
                        onClick={onSubmit}
                        loading={loading}
                    >
                        ОК
                    </Button>
                )/* : (
                    <Popover
                        content={(
                            <div style={{ color: 'white' }}>
                                Пожалуйста, заполните прайс-лист перед сохранением
                            </div>
                        )}
                        trigger="click"
                        placement="topRight"
                        color="#ff7875"
                        overlayInnerStyle={{ color: '#fff !important' }}
                    >
                        <Button
                            type="primary"
                            loading={loading}
                        >
                            ОК
                        </Button>
                    </Popover>
                ) */}
            </div>
        </Modal>
    );
};
