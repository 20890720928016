import { useState } from 'react';
import { message } from 'antd';
import { apiPostLogin } from 'src/api/endpoints';
import { MSG_AUTH } from 'src/consts/messages';
import { STATUS_CODES } from 'src/consts/common';
import { LoginFormValues } from 'src/types/login';
import { setUserData, clearStorage } from 'src/utils/sessionStorage';

export const useLogin = () => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const fetchLogin = async (user: LoginFormValues) => {
        try {
            setLoading(true);
            const resultLogin = await apiPostLogin({ ...user });

            if (resultLogin?.data?.token) {
                const userData: Record<string, string | number> = {
                    login: user.username,
                    name: resultLogin.data?.org?.name || '',
                    token: resultLogin.data.token,
                    role: resultLogin.data?.role as string
                };

                if (resultLogin.data?.org?.id) {
                    userData.org = resultLogin.data.org.id;
                }

                if (resultLogin.data?.org?.chatTypes) {
                    userData.chatTypes = resultLogin.data.org.chatTypes as string;
                }

                setUserData(userData);
            }
        } catch (error: any) {
            console.log('error', error);
            const messageError = error.response?.data?.detail
                || error.response?.data?.message || error.message || MSG_AUTH.ERROR;

            message.error({
                content: messageError,
                key: STATUS_CODES.UNAUTHORIZED
            }).then();
            clearStorage();
        } finally {
            setLoading(false);
        }
    };

    return {
        fetchLogin,
        isLoading
    };
};
