import { MSG_VALIDATION } from './messages';

export const DEFAULT_REQUIRED_RULES = [
    {
        required: true,
        message: MSG_VALIDATION.REQUIRED
    }
];

export const DEFAULT_REQUIRED_GROUP_RULES = [
    {
        required: true,
        message: MSG_VALIDATION.REQUIRED_GROUP
    }
];

export const getLengthRule = (len: number) => ([
    {
        len,
        message: `Количество символов должно быть ${len}`
    }
]);

export const PASS_RULES = [
    {
        pattern: new RegExp(/^[^\sа-яА-Я]+$/),
        message: MSG_VALIDATION.PASS_REGEXP
    },
    {
        min: 8,
        message: MSG_VALIDATION.PASS_MIN
    }
];
