import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Form, Input, Modal, Select, Row } from 'antd';
import { IMask, MaskedInput } from 'antd-mask-input';
import { ConsultationType, OrgDTO } from 'src/types/organisation';
import { Roles } from 'src/types/common';
import { DEFAULT_REQUIRED_GROUP_RULES, DEFAULT_REQUIRED_RULES, getLengthRule } from 'src/consts/rules';
import { getPercentString } from 'src/helpers/columns';
import { apiCreateOrg, apiUpdateOrg } from 'src/api/endpoints';

type EditOrgProps = {
    visible: boolean;
    onCancel: () => void;
    organisation?: OrgDTO;
    isAdmin: boolean;
};

const PHONE_MASK = '+7 000 000-00-00';

const activeOptions = [
    { label: 'Неактивна', value: 0 },
    { label: 'Активна', value: 1 }
];

export const EditOrganisation: FC<EditOrgProps> = ({
    visible,
    onCancel,
    organisation,
    isAdmin
}) => {
    const [form] = Form.useForm<OrgDTO>();
    const [loading, setLoading] = useState<boolean>(false);
    const masked = IMask.createMask({ mask: PHONE_MASK });

    useEffect(() => {
        if (organisation) {
            form.setFieldsValue({
                ...organisation,
                percent: getPercentString(organisation?.percent),
                chatTypes: typeof organisation?.chatTypes === 'string' ? organisation?.chatTypes.split(',') : organisation?.chatTypes,
            });
        } else {
            form.setFieldValue('active', 0);
            form.setFieldValue('chatTypes', ['1']);
        }
    }, [organisation]);

    const onSuccess = () => {
        const apiFunc = organisation ? apiUpdateOrg : apiCreateOrg;
        const org = form.getFieldsValue();

        if (organisation) {
            org.phone = organisation.phone;
        }

        setLoading(true);
        apiFunc({
            ...org,
            percent: org?.percent ? Number(org.percent.toString().replace(',', '.')) / 100 : undefined,
            destinationPayFee: org?.destinationPayFee !== undefined ? Number(org?.destinationPayFee) : undefined,
            role: Roles.Org,
            chatTypes: typeof org.chatTypes !== 'string' ? org.chatTypes.join(',') : org.chatTypes,
        })
            .then(() => {
                onCancel();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                onSuccess();
            });
    };

    const normalizeNumber = (value: string) => value.replaceAll(/[^0-9]/g, '');

    return (
        <Modal
            title={organisation ? 'Редактирование организиции' : 'Добавление новой организации'}
            open={visible}
            onOk={onSubmit}
            confirmLoading={loading}
            onCancel={onCancel}
            afterClose={() => {
                form.resetFields();
            }}
        >
            <div id="custom-org-form">
                <Form
                    form={form}
                    layout="vertical"
                >
                    {!!organisation ? (
                        <Form.Item
                            shouldUpdate
                            noStyle
                        >
                            {({ getFieldValue }) => {
                                const phone = getFieldValue('phone');
                                return (
                                    <Form.Item
                                        label="Телефон"
                                        required
                                    >
                                        <Input value={!!phone ? masked.resolve(phone) : phone} disabled />
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name="phone"
                            label="Телефон"
                            rules={DEFAULT_REQUIRED_RULES}
                        >
                            <MaskedInput
                                autoComplete="off"
                                mask={PHONE_MASK}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="name"
                        label="Название организации"
                        rules={DEFAULT_REQUIRED_RULES}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="subTitle"
                        label="Полное наименование организации"
                        rules={DEFAULT_REQUIRED_RULES}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="addressUr"
                        label="Юридический адрес"
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="addressFact"
                        label="Фактический адрес"
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    {isAdmin && (
                        <Form.Item
                            name="active"
                            label="Статус"
                        >
                            <Select
                                placeholder="Выберите статус"
                                options={activeOptions}
                                getPopupContainer={() => document.getElementById('custom-org-form')!}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="chatTypes"
                        label="Типы консультаций"
                        rules={DEFAULT_REQUIRED_GROUP_RULES}

                    >
                        <Checkbox.Group disabled={!isAdmin} style={{ width: '100%' }}>
                            <Row>
                                <Checkbox value="1">{ConsultationType[1]}</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="2">{ConsultationType[2]}</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="3">{ConsultationType[3]}</Checkbox>
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item
                        name="personalAccount"
                        label="Лицевой счет"
                        rules={[
                            ...DEFAULT_REQUIRED_RULES,
                            ...getLengthRule(20)
                        ]}
                        normalize={normalizeNumber}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="BIC"
                        label="БИК"
                        rules={[
                            ...DEFAULT_REQUIRED_RULES,
                            ...getLengthRule(9)
                        ]}
                        normalize={normalizeNumber}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="INN"
                        label="ИНН"
                        rules={[
                            ...DEFAULT_REQUIRED_RULES,
                            ...getLengthRule(10)
                        ]}
                        normalize={normalizeNumber}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="KPP"
                        label="КПП"
                        rules={[
                            ...DEFAULT_REQUIRED_RULES,
                            ...getLengthRule(9)
                        ]}
                        normalize={normalizeNumber}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="terminalIdent"
                        label="Терминал"
                        rules={DEFAULT_REQUIRED_RULES}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="salepointIdent"
                        label="Salepoint"
                        rules={DEFAULT_REQUIRED_RULES}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        name="percent"
                        label="Процент"
                        help="Введите значение от 0 до 100"
                    >
                        <Input autoComplete="off" suffix="%" />
                    </Form.Item>
                    <Form.Item
                        name="destinationPayFee"
                        valuePropName="checked"
                    >
                        <Checkbox>
                            Процент внутри
                        </Checkbox>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
