import { Roles } from './common';

export enum ConsultationType {
    'Текстовая консультация' = 1,
    'Голосовая консультация' = 2,
    'Видео-консультация' = 3
}

export type OrgDTO = {
    id: number;
    name: string;
    phone: string;
    subTitle?: string;
    terminalIdent?: string;
    salepointIdent?: string;
    INN?: string;
    KPP?: string;
    BIC?: string;
    personalAccount?: string;
    percent?: number | string;
    destinationPayFee?: number;
    pwd?: string;
    active?: 0 | 1;
    toTop?: boolean;
    isTes?: boolean;
    status: number;
    addressUr?: string;
    addressFact?: string;
    chatTypes: string | string[];
};

export type Org = OrgDTO & { role: Roles };

export type OrgResp = {
    list: OrgDTO[];
    total: number;
};

export type OrgPwdData = {
    password: string;
    phone: string;
};
