import React, { FC } from 'react';
import {Button, InputNumber} from 'antd';
import { ConsultationType } from 'src/types/organisation';
import {DeleteOutlined} from "@ant-design/icons";

type EditPriceProps = {
    priceList?: Record<string, { id: number, price?: number }>;
    updatePrice: (val?: Record<string, { id: number, price?: number }>) => void;
};

export const EditPriceForm: FC<EditPriceProps> = ({ priceList, updatePrice }) => {
    const onChangePrice = (key: string, value: number | null) => {
        updatePrice({
            ...priceList,
            [key]: {
                id: priceList?.[key]?.id || 0,
                price: value !== null ? value * 100 : undefined
            }
        });
    };

    const onDeletePrice = (key: string) => {
        updatePrice({
            ...priceList,
            [key]: {
                id: priceList?.[key]?.id || 0,
                price: undefined
            }
        });
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            {priceList ? Object.entries(priceList).map(([key, data]) => (
                <div key={key} style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                    <div style={{ width: 160 }}>{ConsultationType[+key] || 'Тип не задан'}</div>
                    <InputNumber<number>
                        placeholder="0"
                        value={data?.price !== undefined ? data.price/100 : undefined}
                        autoComplete="off"
                        min={0}
                        onChange={(val) => onChangePrice(key, val)}
                        addonAfter="Руб"
                        style={{ width: 200 }}
                        decimalSeparator=","
                    />
                    {data?.price !== undefined && (
                        <Button
                            danger
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={() => onDeletePrice(key)}
                        />
                    )}
                </div>
            )) : (
                <div>Для организации не заданы типы консультаций</div>
            )}
        </div>
    );
}
